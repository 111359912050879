<script>
export default {
	name: 'EditSearchBST',
};
</script>

<script setup>
import dayjs from 'dayjs';
import TransitionHeight from '~/components/transition/TransitionHeight.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import ONDPicker from '~/components/BookingWidget/ONDPicker.vue';
import DateRangePicker from '~/components/BookingWidget/DateRangePicker.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import PassengerAndCabinClass from '~/components/BookingWidget/PassengerAndCabinClass.vue';
import { useBSTOverlayInterimData } from '~composables/booking-widget/useBSTOverlayInterimData.js';
import { waitFor } from '~/logic/helpers/utils.js';
import { useIsUkVariant } from '~/logic/composables/booking-widget/usePassengers.js';
import { i18nGlobal } from '~/logic/i18n.js';

const props = defineProps({
	// modelValue: { type: String, default: '' },
	bstEditSearchCTAText: { type: String, default: i18nGlobal.t('Edit Search') },

	ondSelection: { type: Object, default: null },
	datePicker: { type: Object, default: null },
	passengerSelection: { type: Object, default: null },
	fromQuestionMobile: { type: String, default: '' },
	toQuestionMobile: { type: String, default: '' },
	datePickerQuestionMobile: { type: String, default: '' },
	passengerSelectionQuestion: { type: String, default: '' },

	extraONDFilterForFlightSearch: { type: Object, default: null },

	componentIdAA: { type: String, default: '' },
});
const emit = defineEmits([
	'save-search-data',
]);

const isEditMode = ref(false);

const ondPickerEl = ref(null);

const formWrapper = ref(null);
const passengerAndCabinClassEl = ref(null);
const dateRangePickerEl = ref(null);

const dateRangeValueFrom = ref(null);
const dateRangeValueTo = ref(null);

const ondValueFrom = ref(null);
const ondValueTo = ref(null);


const displayIsOneWay = ref(false);
const displayLocationFrom = ref(null);
const displayLocationTo = ref(null);
const displayDateFrom = ref(null);
const displayDateTo = ref(null);


const handleSubmitValid = (values, actions) => {
	console.log('✅ handleSubmitValid values = ', values);
	
	displayLocationFrom.value = ondValueFrom.value;
	displayLocationTo.value = ondValueTo.value;
	displayDateFrom.value = dateRangeValueFrom.value;
	displayDateTo.value = dateRangeValueTo.value;
	displayIsOneWay.value = !dateRangeValueTo.value;
	
	emit('save-search-data', {
		ondValueFrom: ondValueFrom.value,
		ondValueTo: ondValueTo.value,
		
		dateRangeValueFrom: dateRangeValueFrom.value,
		dateRangeValueTo: dateRangeValueTo.value,
		isOneWay: dateRangePickerEl.value.isOneWay,
		
		cabinClassValue: passengerAndCabinClassEl.value.cabinClassValue,
		adultsCount: passengerAndCabinClassEl.value.adults.count,
		teensCount: passengerAndCabinClassEl.value.teens.count,
		childrenCount: passengerAndCabinClassEl.value.children.count,
		infantsCount: passengerAndCabinClassEl.value.infants.count,
	});
	
	isEditMode.value = false;
};
const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};

const isBtnSubmitDisabled = computed(() => {
	const form = formWrapper.value;
	if (!form) return true;
	return (
		!form.values.locationFrom ||
		!form.values.locationTo ||
		!form.values.dateDeparture ||
		(!dateRangePickerEl.value.isOneWay && !form.values.dateReturn) ||
		Object.keys(form.errors).length !== 0
	);
});

watch([ondValueFrom, ondValueTo], (newValue) => {
	// reset teen passenger count, once user select non-uk airport
	const isUK = useIsUkVariant({ origin: ondValueFrom.value, destination: ondValueTo.value });
	if (isUK) return;
	passengerAndCabinClassEl.value.setValues({
		teens: 0,
	});
});

const { registerNotifier, unregisterNotifier } = useBSTOverlayInterimData();

onMounted(() => {
	registerNotifier('bst-overlay', async (newData) => {
		const ondValueFrom_prev = ondValueFrom.value;
		const ondValueTo_prev = ondValueTo.value;
		
		ondValueFrom.value = newData.ondValueFrom;
		ondValueTo.value = newData.ondValueTo;
		
		if (ondValueFrom.value !== ondValueFrom_prev) {
			// intentionally do not await it
			nextTick(() => {
				ondPickerEl.value?.updateFromValue();
			});
		}
		if (ondValueTo.value !== ondValueTo_prev) {
			// intentionally do not await it
			nextTick(() => {
				ondPickerEl.value?.updateToValue();
			});
		}
		
		dateRangeValueFrom.value = newData.dateRangeValueFrom;
		dateRangeValueTo.value = newData.dateRangeValueTo;
		
		displayIsOneWay.value = newData.isOneWay;
		displayLocationFrom.value = ondValueFrom.value;
		displayLocationTo.value = ondValueTo.value;
		displayDateFrom.value = dateRangeValueFrom.value;
		displayDateTo.value = dateRangeValueTo.value;
		
		const wait = waitFor(() => !!passengerAndCabinClassEl.value, 400, 30); // <-- 400ms * 30 = 12 seconds
		await wait.start();
		
		passengerAndCabinClassEl.value.setValues({
			adults: newData.adultsCount,
			teens: newData.teensCount,
			children: newData.childrenCount,
			infants: newData.infantsCount,
			cabinClass: newData.cabinClassValue,
		});
		
		// set one-way
		dateRangePickerEl.value.handleSetIsOneWay(!!newData.isOneWay);
	}, { immediate: true });
});

onUnmounted(() => {
	unregisterNotifier('bst-overlay');
});

defineExpose({
	isEditMode,
});

</script>

<template>
<div
	class="EditSearchBST"
	:class="{
		'is-edit-mode': isEditMode,
	}"
>
	<div class="flex md:flex-col md:gap-4">
		<div class="flex-grow">
			<div class="flex items-center font-semibold">
				<span v-if="displayLocationFrom" class="">
					{{ displayLocationFrom.displayValue }} ({{ displayLocationFrom.code }})
				</span>
				<icon-far-right-long v-if="displayIsOneWay" class="mx-4" aria-hidden="true" />
				<icon-far-arrow-right-arrow-left v-else class="mx-4" aria-hidden="true" />
				<span v-if="displayLocationTo" class="">
					{{ displayLocationTo.displayValue }} ({{ displayLocationTo.code }})
				</span>
			</div>
			
			<time class="block mt-1 typo-body-text-2">
				<template v-if="dayjs(displayDateTo).isValid()">
					{{ $t('{dateFrom} to {dateTo}', {
						dateFrom: dayjs(displayDateFrom).format('DD MMM YYYY'),
						dateTo: dayjs(displayDateTo).format('DD MMM YYYY'),
					}) }}
				</template>
				<template v-else>
					<span>{{ dayjs(displayDateFrom).format('DD MMM YYYY') }}</span>
				</template>
			</time>
		</div>
		<div v-if="!isEditMode" class="ml-auto">
			<button
				v-aa="[
					{
						clickName: props.bstEditSearchCTAText,
						clickComponentType: 'Button',
						componentName: 'EditSearchBST',
						componentID: props.componentIdAA,
						BST: true,
					},
					{
						name: props.bstEditSearchCTAText,
						type: 'other',
					},
				]"
				v-button-press
				type="button"
				class="flex gap-2 items-baseline text-primary-blue-base font-semibold btn-generic-b px-4 py-1.5 leading-none"
				@click="isEditMode = true"
			>
				<icon-fas-pen-to-square class="fill-current" />
				{{ props.bstEditSearchCTAText }}
			</button>
		</div>
	</div>
	<TransitionHeight
		:class="isEditMode ? 'overflow-visible' : ''"
		:height="isEditMode ? 'auto' : 0"
	>
		<div v-show="isEditMode" class="pt-6">
			<FormWrapper
				ref="formWrapper"
				v-slot="{ errors }"
				@submit-valid="handleSubmitValid"
				@submit-invalid="handleSubmitInvalid"
			>
				<div class="flex gap-6 lg:flex-col">
					<div class="w-6/10 lg:w-full">
						<ONDPicker
							ref="ondPickerEl"
							v-model:modelValueFrom="ondValueFrom"
							v-model:modelValueTo="ondValueTo"
							nameFrom="locationFrom"
							nameTo="locationTo"
							:requiredFrom="true"
							:requiredTo="true"
							:requiredErrorMsgFrom="props.ondSelection?.fromSearchErrorMessage"
							:requiredErrorMsgTo="props.ondSelection?.toSearchErrorMessage"
							:labelTextFrom="props.ondSelection?.fromLabel"
							:labelTextTo="props.ondSelection?.toLabel"
							:noResultsText="props.ondSelection?.noResultMessage"
							ondListIdentifier="FLIGHT_SEARCH_BST"

							:specificOriginList="props.extraONDFilterForFlightSearch?.ondSelection?.showSpecificOrigin"
							:specificDestinationList="props.extraONDFilterForFlightSearch?.ondSelection?.showSpecificDestination"
							:hideSpecificOriginList="props.extraONDFilterForFlightSearch?.ondSelection?.hideSpecificOrigin"
							:hideSpecificDestinationList="props.extraONDFilterForFlightSearch?.ondSelection?.hideSpecificDestination"
							
							:additionalONDFilter="props.extraONDFilterForFlightSearch?.additionalONDFilter"
						>
							<template #from-picker-mobile-title>
								<span>{{ props.fromQuestionMobile }}</span>
							</template>
							<template #to-picker-mobile-title>
								<span>{{ props.toQuestionMobile }}</span>
							</template>
						</ONDPicker>
					</div>
					<div class="w-4/10 lg:w-full">
						<DateRangePicker
							ref="dateRangePickerEl"
							v-model:modelValueFrom="dateRangeValueFrom"
							v-model:modelValueTo="dateRangeValueTo"
							:textFieldAttrsFrom="{
								ariaLabel: props.datePicker?.departureDateLabel ?? $t('Depart'),
								placeholder: $t('Select a date'),
							}"
							:textFieldAttrsTo="{
								ariaLabel: props.datePicker?.returnDateLabel ?? $t('Return'),
								placeholder: $t('Select a date'),
							}"
							maxDate="t+360d"
							:disablePastDates="true"
							nameFrom="dateDeparture"
							nameTo="dateReturn"
							:requiredFrom="true"
							:requiredTo="true"
							:requiredErrorMsgFrom="props.datePicker?.invalidDateErrorMessage"
							:requiredErrorMsgTo="props.datePicker?.invalidDateErrorMessage"
							:labelReset="props.datePicker?.resetButtonCTAText ?? $t('Reset')"
							:labelDone="props.datePicker?.doneCTAText ?? $t('Done')"
							:labelOneWay="props.datePicker?.oneWayCTAText ?? $t('One-way')"
						>
							<template #mobile-title>
								{{ props.datePickerQuestionMobile }}
							</template>
						</DateRangePicker>
					</div>
				</div>
				
				<div class="flex mt-6 gap-6 md:flex-col">
					<div class="flex flex-grow gap-6 md:flex-col">
						<div class="w-6/10 lg:w-full">
							<PassengerAndCabinClass
								ref="passengerAndCabinClassEl"
								:labelText="props.passengerSelection?.passengerAndCabinClassLabel"
								:passengerLabel="props.passengerSelection?.passengerLabel"
								:adultLabel="props.passengerSelection?.adultLabel"
								:teenagerLabel="props.passengerSelection?.teenagerLabel"
								:childrenLabel="props.passengerSelection?.childrenLabel"
								:infantLabel="props.passengerSelection?.infantLabel"
								:cabinClassLabel="props.passengerSelection?.cabinClassLabel"
								:economyLabel="props.passengerSelection?.economyLabel"
								:businessLabel="props.passengerSelection?.businessLabel"
								:businessSuiteLabel="props.passengerSelection?.businessSuiteLabel"
								:isShowEconomyClass="!props.passengerSelection?.hideEconomy"
								:isShowBusinessClass="!props.passengerSelection?.hideBusiness"
								:isShowBusinessSuiteClass="!props.passengerSelection?.hideBusinessSuite"
								:isUKVariant="useIsUkVariant({ origin: ondValueFrom, destination: ondValueTo })"
							>
								<template #after-passengers>
									<AppHyperlink
										class="font-semibold"
										:href="props.passengerSelection?.groupBookingPageURL"
										:showExternalLinkIcon="true"
										target="_blank"
									>
										{{ props.passengerSelection?.moreThan9PassengerDescription || $t('More than 9 passengers?') }}
									</AppHyperlink>
								</template>

								<template #mobile-title>
									{{ props.passengerSelectionQuestion }}
								</template>
							</PassengerAndCabinClass>
						</div>
					</div>
					<div class="ml-auto md:ml-0">
						<CTAButton
							v-aa="[
								{
									clickName: $t('Save'),
									clickComponentType: 'Button',
									componentName: 'EditSearchBST',
									componentID: props.componentIdAA,
									BST: true,
								},
								{
									name: $t('Save'),
									type: 'other',
								},
							]"
							class="btn-save"
							:sizeType="isMobileViewport ? 'small' : 'large'"
							:isSubmit="true"
							:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
							:disabled="isBtnSubmitDisabled"
						>
							{{ $t('Save') }}
						</CTAButton>
					</div>
				</div>
			</FormWrapper>
		</div>
	</TransitionHeight>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.EditSearchBST {
	background-color: var(--neutral-grey-ultralight);
	border: 2px solid var(--neutral-grey-light);
	transition: background-color 0.2s;
	@apply rounded-xl px-5 py-4;
	
	&.is-edit-mode {
		background-color: white;
	}
}

:deep(.CTAButton.btn-save) {
	min-width: 158px;
}

</style>
